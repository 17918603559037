import React from 'react'
import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import FAQ from '../../components/FAQ'
import { Link } from 'gatsby'
import styles from '../../components/layout.module.scss'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'

class Page extends React.Component {

    render() {

        return (

            <Layout location={this.props.location}>

                <SEO
                    title="4 steps to post audio on facebook"
                    description="Step by step guide for converting your audio content to a video you can post on Facebook in 2021. Maximise your audio engagement!"
                />

                <article>
                    <h1>How to post audio on facebook?</h1>
                    <section>

                        <p><strong>Its not possible to share MP3 Audio files directly to Facebook️</strong> Luckily we have a few work around options to help you share your audio.</p>

                        <p>Facebook is a hub for discovering new music and podcasts; While you cannot post audio directly to facebook, in this guide we will show you how to <strong><a
                            href="https://app.echowave.io">share your audio on Facebook</a> in four steps:</strong> </p>

                        <h2>Converting your Audio to Video</h2>

                        <ol>
                            <li id='step1'>
                                <strong>Convert the audio to a video file.</strong>
                                <p>Facebook cannot post audio files (mp3/wav), instead, you can transform your audio to a video (mp4) then upload your audio to facebook.</p>
                            </li>
                            <li id='step2'>
                                <strong>Add a background art to the video</strong>
                                <p>Choose a background for your new videos such as your podcast or music cover art - You can also use a solid colour and add some text.</p>
                            </li>
                            <li id='step3'>
                                <strong>Add audio wave animation</strong>
                                <p>To increase motion, and make your videos feel more dynamic you can add a waveform animation synced to your audio.</p>
                            </li>
                            <li id='step4'>
                                <strong>Post the Video facebook</strong>
                                <p>Once the video has rendered you can post audio online, directly to facebook, or any social media site.</p>
                            </li>
                        </ol>


                        <script type="application/ld+json">{`
                            {
                                "@context": "http://schema.org",
                                "@type": "HowTo",
                                "name": "How to post audio on Facebook",
                                "description": "Steps to convert your audio to video and share on facebook.",
                                "image": {
                                "@type": "ImageObject",
                                "url": "https://echowave.io/images/audio-convert.jpg",
                                "height": "491",
                                "width": "900"
                            },
                                "estimatedCost": {
                                "@type": "MonetaryAmount",
                                "currency": "USD",
                                "value": "0"
                            },
                                "tool": [
                            {
                                "@type": "HowToTool",
                                "name": "Facebook Account"
                            }, {
                                "@type": "HowToTool",
                                "name": "Echowave Account"
                            }, {
                                "@type": "HowToTool",
                                "name": "PC or Mobile Phone"
                            }
                                ],
                                "step": [
                                {
                                    "@type": "HowToStep",
                                    "name": "Convert audio to a video",
                                    "url": "https://echowave.io/how-to-post-audio-on-facebook/#step1",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Facebook cannot post audio files (mp3/wav), instead, you can transform your audio to a video (mp4) then upload your audio to facebook."
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-1.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Add Background",
                                    "url": "https://echowave.io/how-to-post-audio-on-facebook/#step2",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Choose a background for your new videos such as your podcast or music cover art - You can also use a solid colour and add some text."
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-2.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Wave Animation",
                                    "url": "https://echowave.io/how-to-post-audio-on-facebook/#step3",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "To increase motion, and make your videos feel more dynamic you can add a waveform animation synced to your audio."
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-3.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                },
                                {
                                    "@type": "HowToStep",
                                    "name": "Post to Facebook",
                                    "url": "https://echowave.io/how-to-post-audio-on-facebook/#step4",
                                    "itemListElement": [
                                        {
                                            "@type": "HowToDirection",
                                            "text": "Once the video has rendered you can post this audio directly to facebook, or any social media site."
                                        }
                                    ],
                                    "image": {
                                        "@type": "ImageObject",
                                        "url": "https://echowave.io/images/steps/step-4.png",
                                        "height": "500",
                                        "width": "500"
                                      }
                                }
                                ],
                                "totalTime": "PT10M"
                            }`}
                        </script>


                        <p>The video conversion method lets you post your sound directly to Facebook wall without having to link to an external account. The video will show up directly in user feeds and in many cases will start autoplaying as users scroll on their timeline, increasing engagement with your post!</p>

                    </section>

                    <section>
                        <GetStartedOrChat cta="Ready to convert your audio into a video to share on facebook?" />
                        <br/>
                    </section>

                    <Link to='/podcast-to-video/' >
                        <img style={{ 'width': '100%' }} src="/images/audio-convert.jpg" alt="How to convert audio to video"/>
                    </Link>

                    <section>
                        <h2>Alternative Methods</h2>

                        <h3>File Hosting</h3>

                        <p>
                            You can use a hosting service to store the audio file then post a link. For this, you can use Google Drive, <a href="https://www.dropbox.com/" target="_blank">Dropbox</a>, iCloud or similar. Upload your sound in the normal way then generate a preview link. This link can now be shared to Facebook.
                        </p>

                        <p>
                            The generated link will not be as visual eyecatching as uploading a video, however, it works somewhat flawlessly - And is a good free solution.
                        </p>

                        <p>
                            Add audio to Facebook:

                            <ol>
                                <li>Create a DropBox / Google Drive account</li>
                                <li>Upload your sound file</li>
                                <li>Tap the share icon</li>
                                <li>Choose create a link</li>
                                <li>Post the link using your Facebook account</li>
                            </ol>

                        </p>



                        <h3>Sound Cloud</h3>

                        <p>Sound cloud is an online media player, Luckily Facebook supports sound cloud links so you share your music/audio directly to Soundcloud, using the generated link you can then share this on Facebook. There are a few caveats though such as limited sample rates, and your audio has to be public for Facebook to access.</p>

                        <p>To share your audio on Facebook using SoundCloud follow these steps:</p>

                        <ol>
                            <li>Create a free <a href="https://soundcloud.com/" target="_blank">SoundCloud</a> Account</li>
                            <li>Click the upload button and choose your audio file</li>
                            <li>Click the Facebook icon to Share on Facebook</li>
                            <li>You can now post a link to your audio file</li>
                        </ol>


                    </section>

                    <section>
                        <br/>
                        <h2>Frequently Asked Questions</h2>
                        <FAQ items={[
                            {
                                'question': 'Why do I have to convert my audio to video',
                                'answer': 'Facebook only supports video, text and image posts. EchoWave helps you simply transform your audio into an engaging video to share.'
                            },
                            {
                                'question': 'How to upload an audio file to Facebook?',
                                'answer': 'Convert your audio to a video file using EchoWave, add music and then you can upload the video directly to facebook'
                            },
                            {
                                'question': 'How to promote my podcast?',
                                'answer': 'Once you have generated a video for your podcast, you can share on FB groups, your podcast page or anywhere on Facebook. You can even pay to advertise your video to give an extra boost.'
                            },
                            {
                                'question': 'How long can videos on facebook be?',
                                'answer': 'The optimum length for a video on Facebook is 60-90 seconds, and the maximum duration is 45 Minutes.'
                            },
                            {
                                'question': 'How to automatically upload my podcast to FB?',
                                'answer': 'The EchoWave API enables automatically posting your podcast to facebook as a video.'
                            },
                            {
                                'question': 'What alternatives are there to converting audio to video?',
                                'answer': 'Instead of converting audio to video you can use an embed from a third party, such as Spotify, Dropbox or SoundCloud - Just copy the link from the service and post to facebook.'
                            },
                            {
                                'question': 'What audio file types can you upload to facebook?',
                                'answer': 'You can’t upload audio to facebook, however, you can transform many file formats such as MP3s, WAV, & FLAC file it into a MP4 video clip, to then publish on facebook'
                            }
                        ]} />

                    </section>

                    <section>
                        <h2>Conclusion</h2>
                        <p>Whether you choose to share an engaging video, a link to your sound or use SoundCloud all these options enable you to share your sound to your Facebook page followers.</p>
                        <p><small>*It should be noted that Facebook has some Video duration limits, however, if you use Hosting  You limit will be File Size, Often 20GB. </small></p>
                    </section>

                </article>


            </Layout>

        )
    }

}

export default Page
